import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { trackingAdapter } from './adapter'
import * as actions from './actions'
import { findLatLngDiff } from './../../lib/helper'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

function* loadDashcamera(action) {
  const {
    data: {
      token,
      page,
      pageSize,
      searchName,
      meterStatus,
      engineStatus,
      emergencyStatus,
      speedRange,
      companyID,
      vehicleVisibility,
      cb,
    },
  } = action.payload
  // const company_id =
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefordashcam`,
      {
        searchName,
        page,
        pageSize,
        meterStatus,
        engineStatus,
        emergencyStatus,
        speedRange,
        companyID,
        vehicleVisibility,
        cb,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    cb()
    yield put(actions.loadDashcamera.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDashcamera.failure())
  }
}

function* loadEmergencyCase(action) {
  const {
    data: { companyID, token, vehicleVisibility },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getemergencycase`,
      {
        companyID,
        vehicleVisibility,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.loadEmergencyCase.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadEmergencyCase.failure())
  }
}

function* loadEarningWidget(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getearningwidgetinfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.loadEarningWidget.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadEarningWidget.failure())
  }
}

function* loadAccEarning(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getaccumulateearninginfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.loadAccEarning.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadAccEarning.failure())
  }
}

function* loadBookingWidget(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getbookingwidgetinfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.loadBookingWidget.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadBookingWidget.failure())
  }
}

function* loadAccBooking(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getaccumulatebookinginfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.loadAccBooking.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadAccBooking.failure())
  }
}

function* loadTopBookingData(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/gettopinterestingbooking?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.loadTopBookingData.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadTopBookingData.failure())
  }
}

function* loadTrackingInitData(action) {
  const {
    vendor_id,
    section_id,
    department_id,
    division_id,
    searchName,
    selectedTrackingColumns,
    vehicleStatus,
    engineStatus,
    speedRange,
    timeRange,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    cbLoadTrackingInitData,
    reCalMarker,
    previousState,
    mode,
  } = action.payload.data

  try {
    var credentials = btoa('cpactracking1234' + ':' + 'auioPdke9i3')
    var basicAuth = 'Basic ' + credentials

    const url =
      mode === 1
        ? `${process.env.REACT_APP_REDIS_API_ENDPOINT}/sale-order-for-tracking-map`
        : `${process.env.REACT_APP_REDIS_API_ENDPOINT}/getvehiclefortrackingmap`

    const res = yield call(
      axios.post,
      url,
      {
        gps_vendor_id: Number(vendor_id),
        section_id: section_id.length == 0 ? 0 : Number(section_id[0]),
        department_id: department_id.length == 0 ? 0 : Number(department_id[0]),
        division_id: division_id.length == 0 ? 0 : Number(division_id[0]),
        company_id: companyID,
        vehicle_visibility: vehicle_visibility
          .split(',')
          .map(function(vehicle_visibility) {
            return Number(vehicle_visibility)
          }),
        search_name: searchName,
        selected_tracking_columns: selectedTrackingColumns,
        vehicle_status: vehicleStatus,
        // engineStatus,
        speed_range: speedRange,
        time_range: timeRange,
        // dataTreeFilter,
        vehicle_type:
          dataTreeFilter &&
          dataTreeFilter
            .filter(ele => {
              if (ele.includes('vehicle_type_id')) return true
              return false
            })
            .map(ele2 => {
              let ids = ele2.split('vehicle_type_id_')
              return ids[1]
            }),
        vehicle_group:
          dataTreeFilter &&
          dataTreeFilter
            .filter(ele => {
              if (ele.includes('vehicle_group_id')) return true
              return false
            })
            .map(ele2 => {
              let ids = ele2.split('vehicle_group_id_')
              return ids[1]
            }),
      },
      { headers: { Authorization: `${basicAuth}` } },
    )

    res.data.markers = trackingAdapter(res.data.markers)

    typeof cbLoadTrackingInitData === 'function' &&
      cbLoadTrackingInitData(res.data.markers, true)
    // setTimeout(() => {
    reCalMarker && reCalMarker()
    // }, 500);
    yield put(
      actions.loadTrackingInitData.success({
        markers: res.data.markers.map(ele => {
          let diff = 1

          if (
            typeof ele.current_location != 'undefined' &&
            ele.current_location != null
          )
            diff = ele.current_location.length > 0 ? 0 : 1
          return { ...ele, accLatLngDiff: diff }
        }),
      }),
    )
  } catch (err) {
    console.log(err)
    if (typeof cbLoadTrackingInitData === 'function') {
      cbLoadTrackingInitData([], false)
    }
    yield put(actions.loadTrackingInitData.failure())
  }
}

function* loadTracking(action) {
  const diffthreshold = 0.09
  const {
    vendor_id,
    section_id,
    department_id,
    division_id,
    searchName,
    selectedTrackingColumns,
    vehicleStatus,

    speedRange,
    timeRange,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    cbLoadTracking,
    previousState,
    reCalMarker,
    mode,
    site_code,
    vehicle_code,
    order_id,
  } = action.payload.data

  try {
    var credentials = btoa('cpactracking1234' + ':' + 'auioPdke9i3')
    var basicAuth = 'Basic ' + credentials

    const url =
      mode === 1
        ? `${process.env.REACT_APP_REDIS_API_ENDPOINT}/sale-order-for-tracking-map`
        : `${process.env.REACT_APP_REDIS_API_ENDPOINT}/getvehiclefortrackingmap`
    const res = yield call(
      axios.post,
      //`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefortrackingmap`,
      url,
      {
        gps_vendor_id: Number(vendor_id),
        section_id: section_id.length == 0 ? 0 : Number(section_id[0]),
        department_id: department_id.length == 0 ? 0 : Number(department_id[0]),
        division_id: division_id.length == 0 ? 0 : Number(division_id[0]),
        company_id: companyID,
        vehicle_visibility: vehicle_visibility
          .split(',')
          .map(function(vehicle_visibility) {
            return Number(vehicle_visibility)
          }),
        search_name: searchName,
        selected_tracking_columns: selectedTrackingColumns,
        vehicle_status: vehicleStatus,
        // engineStatus,
        speed_range: speedRange,
        time_range: timeRange,
        // dataTreeFilter,
        vehicle_type:
          dataTreeFilter &&
          dataTreeFilter
            .filter(ele => {
              if (ele.includes('vehicle_type_id')) return true
              return false
            })
            .map(ele2 => {
              let ids = ele2.split('vehicle_type_id_')
              return ids[1]
            }),
        vehicle_group:
          dataTreeFilter &&
          dataTreeFilter
            .filter(ele => {
              if (ele.includes('vehicle_group_id')) return true
              return false
            })
            .map(ele2 => {
              let ids = ele2.split('vehicle_group_id_')
              return ids[1]
            }),
        site_code,
        vehicle_code,
        order_id,
      },
      { headers: { Authorization: `${basicAuth}` } },
    )

    ///////////////////////////////////////////////////////////////////

    let responseData = trackingAdapter(res.data.markers)

    let haveCurrentLocationColumn = selectedTrackingColumns.find(
      ele => ele == 'current_location',
    )

    if (typeof haveCurrentLocationColumn != 'undefined') {
      let location = yield call(
        axios.post,
        // `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationfromthirdparty?lat=${val.latest_lat}&lng=${val.latest_lng}`,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationfromthirdparty`,
        {
          companyID: companyID,
          vehicleList: responseData
            .filter(ele => {
              let previousVal = previousState.find(
                ele2 => ele2.device_id == ele.device_id,
              )

              if (ele.current_location == null) return false
              if (typeof previousVal != 'undefined') {
                if (previousVal.accLatLngDiff < diffthreshold) return false
              } else {
                return ele.current_location.length == 0
              }
              return ele.current_location.length == 0
            })
            .map(ele => {
              return {
                device_id: ele.device_id,
                lat: ele.latest_lat,
                lng: ele.latest_lng,
              }
            }),
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )

      for (let index = 0; index < responseData.length; index++) {
        let val = responseData[index]
        let previousVal = previousState.find(
          ele2 => ele2.device_id == val.device_id,
        )

        if (val.current_location == null) {
          responseData[index] = {
            ...val,
            current_location: [],
            accLatLngDiff: 0,
          }
        } else if (val.current_location.length > 0) {
          responseData[index] = {
            ...val,
            current_location: val.current_location,
            accLatLngDiff: 0,
          }
        } else {
          if (typeof previousVal != 'undefined') {
            let diffVal = findLatLngDiff(
              previousVal.latest_lat,
              previousVal.latest_lng,
              val.latest_lat,
              val.latest_lng,
            )
            let findVal = location.data.find(
              ele => ele.device_id == val.device_id,
            )
            if (previousVal.accLatLngDiff < diffthreshold) {
              if (typeof findVal != 'undefined') {
                responseData[index] = {
                  ...val,
                  current_location: findVal.current_location,
                  accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                }
              } else {
                if (val.current_location.length == 0) {
                  if (previousVal.current_location.length > 0) {
                    if (
                      typeof previousVal.current_location[0].base_color_code ==
                      'undefined'
                    ) {
                      responseData[index] = {
                        ...val,
                        current_location: previousVal.current_location,
                        accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                      }
                    } else {
                      responseData[index] = {
                        ...val,
                        current_location: val.current_location,
                        accLatLngDiff: 1,
                      }
                    }
                  } else {
                    responseData[index] = {
                      ...val,
                      current_location: previousVal.current_location,
                      accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                    }
                  }
                } else {
                  responseData[index] = {
                    ...val,
                    current_location: previousVal.current_location,
                    accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                  }
                }
              }
            } else {
              if (typeof findVal != 'undefined') {
                responseData[index] = {
                  ...val,
                  current_location: findVal.current_location,
                  accLatLngDiff: 0,
                }
              } else {
                // responseData[index] = {
                //   ...val,
                //   current_location: previousVal.current_location,
                //   accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                // };

                if (val.current_location.length == 0) {
                  if (previousVal.current_location.length > 0) {
                    if (
                      typeof previousVal.current_location[0].base_color_code ==
                      'undefined'
                    ) {
                      responseData[index] = {
                        ...val,
                        current_location: previousVal.current_location,
                        accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                      }
                    } else {
                      responseData[index] = {
                        ...val,
                        current_location: val.current_location,
                        accLatLngDiff: 1,
                      }
                    }
                  } else {
                    responseData[index] = {
                      ...val,
                      current_location: val.current_location,
                      accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                    }
                  }
                } else {
                  responseData[index] = {
                    ...val,
                    current_location: previousVal.current_location,
                    accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                  }
                }
              }
            }
          } else {
            if (val.current_location.length == 0) {
              let findVal = location.data.find(
                ele => ele.device_id == val.device_id,
              )
              if (typeof findVal.current_location != 'undefined')
                responseData[index] = {
                  ...val,
                  current_location: findVal.current_location,
                  accLatLngDiff: 0,
                }
              else
                responseData[index] = {
                  ...val,
                  current_location: [],
                  accLatLngDiff: 1,
                }
            }
          }
        }
      }
    }

    ///////////////////////////////////////////////////////////////////
    cbLoadTracking(responseData, true)
    reCalMarker && reCalMarker(responseData)
    yield put(actions.loadTracking.success({ markers: responseData }))
  } catch (err) {
    console.error(err)
    console.log(err.message)
    cbLoadTracking([], false)
    // console.log(err.stack)
    yield put(actions.loadTracking.failure())
  }
}

function* loadVehicleTypeMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data

  try {
    const res = yield call(
      axios.post,
      //   `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicletype?vehicle_visibility=[${vehicle_visibility}]`,
      //   { headers: { Authorization: `Bearer ${token}` } }
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicletypetracking`,
      {
        vehicle_visibility: `[${vehicle_visibility}]`,
      },
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )

    yield put(actions.loadVehicleTypeMasterData.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadVehicleTypeMasterData.failure())
  }
}

function* loadVehicleGroupMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data

  try {
    const res = yield call(
      axios.post,
      //   `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclegroup?company_id=[${companyID}]&vehicle_visibility=[${vehicle_visibility}]`,
      //   { headers: { Authorization: `Bearer ${token}` } },
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclegroup`,
      {
        company_id: companyID,
        vehicle_visibility: `[${vehicle_visibility}]`,
      },
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )

    yield put(actions.loadVehicleGroupMasterData.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadVehicleGroupMasterData.failure())
  }
}

function* loadVehicleMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicle?company_id=[${companyID}]&vehicle_visibility=[${vehicle_visibility}]`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.loadVehicleMasterData.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadVehicleMasterData.failure())
  }
}

function* loadAlertAlarm(action) {
  const {
    searchName,
    warningType,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    isSearch,
    cb,
  } = action.payload.data

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclealertalarm`,
      {
        company_id: companyID,
        vehicle_visibility,
        searchName,
        warningType,
        dataTreeFilter,
        cb,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    cb()
    yield put(actions.loadAlertAlarm.success(res.data, isSearch))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadAlertAlarm.failure())
  }
}

function* loadHeatMap(action) {
  const {
    searchName,
    warningType,
    eventType,
    startDateTime,
    endDateTime,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
  } = action.payload.data

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleheatmap`,
      {
        company_id: companyID,
        vehicle_visibility,
        searchName,
        warningType,
        eventType,
        startDateTime,
        endDateTime,
        dataTreeFilter,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.loadHeatMap.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadHeatMap.failure())
  }
}

function* loadSafetyInfo(action) {
  const {
    partner,
    division,
    department,
    section,
    start_at,
    end_at,
    plant_ids,
    only_has_dp,
    intervalLoading,
    vehicle_visibility,
    note,
    cb,
  } = action.payload.data

  try {
    const params = new URLSearchParams()
    params.append('partner', partner)
    params.append('vehicle_visibility', vehicle_visibility)
    params.append('division', division)
    params.append('department', department)
    params.append('section', section)
    params.append('start_at', start_at)
    params.append('end_at', end_at)
    params.append('plant_ids', plant_ids)
    params.append('only_has_dp', only_has_dp)
    params.append('event_type', '3000,1001,7002,2051,1002,2050')
    params.append('note', note)

    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_GO_API_ENDPOINT}/dashboard/safetyinfo`,
      //  `https://fleetlink.cipcloud.net/go/api/dashboard/safetyinfo`,
      params,
      {
        headers: {
          'Content-Type': `application/x-www-form-urlencoded`,
        },
      },
    )

    if (res.data == null) res.data = []
    if (typeof cb != 'undefined') cb()

    yield put(actions.loadSafetyInfo.success(res.data, intervalLoading))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadSafetyInfo.failure())
  }
}

function* saveNoteSafetyInfo(action) {
  try {
    const { note, _id, token, loadData } = action.payload.data

    const params = new URLSearchParams()
    params.append('note', note)
    params.append('_id', _id)
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_GO_API_ENDPOINT}/dashboard/safetyinfo/note`,
      params,
      {
        headers: {
          'Content-Type': `application/x-www-form-urlencoded`,
        },
      },
    )
    loadData()
    yield put(actions.saveNoteSafetyInfo.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.saveNoteSafetyInfo.failure())
  }
}

export default function* watcMonitoringState() {
  yield all([
    takeLatest(actions.LOAD_DASHCAMERA.REQUEST, loadDashcamera),
    takeLatest(actions.LOAD_EMERGENCYCASE.REQUEST, loadEmergencyCase),
    takeEvery(actions.LOAD_EARNINGWIDGET.REQUEST, loadEarningWidget),
    takeEvery(actions.LOAD_ACCEARNING.REQUEST, loadAccEarning),
    takeEvery(actions.LOAD_BOOKINGWIDGET.REQUEST, loadBookingWidget),
    takeEvery(actions.LOAD_ACCBOOKING.REQUEST, loadAccBooking),
    takeEvery(actions.LOAD_TOPBOOKINGDATA.REQUEST, loadTopBookingData),
    takeEvery(actions.LOAD_TRACKING_INIT_DATA.REQUEST, loadTrackingInitData),
    takeEvery(actions.LOAD_TRACKING.REQUEST, loadTracking),
    takeEvery(actions.LOAD_ALERT_ALARM.REQUEST, loadAlertAlarm),
    takeLatest(actions.LOAD_SAFETY_INFO.REQUEST, loadSafetyInfo),
    takeLatest(
      actions.LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER.REQUEST,
      loadVehicleTypeMasterData,
    ),
    takeLatest(
      actions.LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER.REQUEST,
      loadVehicleGroupMasterData,
    ),
    takeLatest(
      actions.LOAD_VEHICLE_MASTER_DATA_FILTER.REQUEST,
      loadVehicleMasterData,
    ),
    takeEvery(actions.LOAD_HEAT_MAP.REQUEST, loadHeatMap),
    takeEvery(actions.SAVE_NOTE_SAFETY_INFO.REQUEST, saveNoteSafetyInfo),
  ])
}

export {
  loadDashcamera,
  loadEmergencyCase,
  loadEarningWidget,
  loadAccEarning,
  loadBookingWidget,
  loadAccBooking,
  loadTopBookingData,
  loadTracking,
  loadTrackingInitData,
  loadVehicleTypeMasterData,
  loadVehicleGroupMasterData,
  loadAlertAlarm,
  loadVehicleMasterData,
  loadHeatMap,
  loadSafetyInfo,
  saveNoteSafetyInfo,
}
