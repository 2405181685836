import React, { Component, Fragment } from 'react'
import { renderToString } from 'react-dom/server'
import {
  Row,
  Col,
  Card,
  Table,
  Select,
  Empty,
  Spin,
  Typography,
  Collapse,
  Button,
  Icon,
  Dropdown,
  Menu,
} from 'antd'
import { debounce } from 'debounce'
import { appendScript, removeScript } from 'lib/useScript'
import LangContext, { i18n } from 'modules/shared/context/langContext'
const ButtonGroup = Button.Group
export class Index extends React.Component {
  mapRef = React.createRef()

  state = {
    // The map instance to use during cleanup
    map: null,
    maps: null,
    allMarker: null,
    mapMount: false,
    loadScriptSuccess: false,
  }

  calculatedCluster = (self, maps, map, allMarker) => {
    if (this.state.allMarker != null) {
      this.state.allMarker.map(ele => map.Overlays.remove(ele))
      this.setState({ allMarker: null })
    }
    let tempMarker = []

    if (typeof map.bound() != 'undefined') {
      let zoom = map.zoom()
      let { maxLat, maxLon, minLat, minLon } = map.bound()
      let center = map.location()

      let resObj = {
        nw: { lat: maxLat, lng: minLon },
        se: { lat: minLat, lng: maxLon },
      }

      if (maxLat == minLat) {
        resObj = {
          nw: { lat: 32.30437568401429, lng: 83.948155875 },
          se: { lat: -6.426047614401924, lng: 117.170812125 },
        }
      }

      self.props.setMapBound({
        center: { lat: center.lat, lng: center.lon },
        zoom: zoom,
        bounds: resObj,
      })

      self.props.marker &&
        self.props.marker.map(ele => {
          let htmlMarker = renderToString(ele)
          let marker = new maps.Marker(
            {
              lon: parseFloat(ele.props.lng.toFixed(6)),
              lat: parseFloat(ele.props.lat.toFixed(6)),
            },
            {
              icon: {
                html: htmlMarker,
                // offset: { x: 12, y: 45 },
              },
            },
          )
          //   console.log(htmlMarker);
          if (typeof ele.props.onClick != 'undefined') {
            // new hObject.map.Group({
            //   objects: [marker],
            // });
            // marker.addEventListener(
            //   "tap",
            //   function(e) {
            //     ele.props.onClick();
            //   },
            //   false
            // );
          }

          // Add the marker to the map:
          map.Overlays.add(marker)
          tempMarker.push(marker)
        })
    }
    return tempMarker
  }

  componentDidMount() {
    appendScript(
      'script',
      'https://api.longdo.com/map/?key=' +
        this.props.auth.profile.longdo_map_key,
      this,
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.loadScriptSuccess != nextState.loadScriptSuccess) {
      this.initMap()
    }
    return true
  }
  foucusBoundary = () => {
    // console.log('focusbound')
    const { minLon, minLat, maxLon, maxLat } = this.props.focusBoundary
    if (minLon === maxLon && minLat === maxLat) {
      // console.log('lat and long', minLon, minLat)
      this.state.map.location({ lon: minLon, lat: minLat }, true)
      this.state.map.zoom(16, true)
    } else {
      // console.log('bound', this.props.focusBoundary)
      this.state.map.bound(this.props.focusBoundary)
    }
  }
  componentDidUpdate(prevProps) {
    // console.log('propsLDM', this.props)
    const { focusBoundary, trickerZoom } = this.props
    // if (prevProps.trickerZoom != trickerZoom) {
    //     console.log('prevPropsmapOptions', prevProps.mapOptions, this.props.mapOptions)
    //     this.state.map.zoom(trickerZoom, true)
    // }
    if (focusBoundary !== null) {
      const { minLon, minLat, maxLon, maxLat } = focusBoundary
      const prev = prevProps.focusBoundary

      if (prev === null) {
        this.foucusBoundary()
        // console.log('focus1')
        return
      }

      if (
        minLon !== prev.minLon ||
        minLat !== prev.minLat ||
        maxLon !== prev.maxLon ||
        maxLat !== prev.maxLat
      ) {
        this.foucusBoundary()
        // console.log('focus2')
      }
    }
  }
  initMap = () => {
    let maps = window.longdo
    let self = this

    let map = null
    // console.log('propsMapOption', this.props.mapOptions)
    map = new maps.Map({
      center: {
        lat: this.props.mapOptions.center.lat,
        lng: this.props.mapOptions.center.lng,
      },
      placeholder: document.getElementById(this.props.refId),
      zoom: this.props.mapOptions.zoom,
    })
    window.addEventListener('resize', () => {
      map.resize()
    })
    map.Ui.Fullscreen.visible(true)
    map.Ui.DPad.visible(false) // เครื่องมือเลื่อนแผนที่ (อยู่บริเวณมุมบนซ้าย)
    map.Ui.Zoombar.visible(false) // แถบซูม (อยู่บริเวณมุมบนซ้าย)
    map.Ui.Geolocation.visible(false) // ปุ่มบอกตำแหน่งปัจจุบัน (อยู่บริเวณมุมบนซ้าย)
    map.Ui.Toolbar.visible(false) // แถบเครื่องมือ (อยู่บริเวณมุมบนซ้าย)
    map.Ui.LayerSelector.visible(false) // ตัวเลือกรูปแบบแผนที (อยู่บริเวณมุมบนขวา)
    map.Ui.Crosshair.visible(false) // สัญลักษณ์แสดงจุดกึ่งกลาง (ตรงกลางแผนที่)
    //map.Layers.setBase(maps.Layers.NORMAL);
    map.zoom(6, true)

    // console.log('getkeyy', this.props.auth.profile.google_map_key)
    map.Layers.externalOptions({
      googleQuery: `key=${this.props.auth.profile.google_map_key}`,
    })
    // map.Layers.setBase(maps.Layers.GOOGLE_ROADMAP)
    map.Ui.Mouse.enableInertia(false)
    map.Ui.Keyboard.enable(false)

    map.Event.bind('drag', () => {
      //   console.log("drag");
      map.Ui.Mouse.enableWheel(false)
      map.Ui.Mouse.enableClick(false)
    })
    map.Event.bind('drop', () => {
      //   console.log("drop");
      map.Ui.Mouse.enableWheel(true)
      map.Ui.Mouse.enableClick(true)
    })
    map.Event.bind('overlayDrag', () => {
      map.Ui.Mouse.enableWheel(false)
      map.Ui.Mouse.enableClick(false)

      map.Ui.Mouse.enable(false)
    })
    map.Event.bind('overlayMove', () => {
      //   console.log("overlayDrag");
      map.Ui.Mouse.enableWheel(false)
      map.Ui.Mouse.enableClick(false)

      map.Ui.Mouse.enable(false)
    })
    map.Event.bind('overlayDrop', () => {
      map.Ui.Mouse.enableWheel(true)
      map.Ui.Mouse.enableClick(true)
      map.Ui.Mouse.enableDrag(true)
      map.Ui.Mouse.enable(true)
    })
    map.Event.bind('zoom', () => {
      // console.log("zoom");
      map.Ui.Mouse.enableClick(false)
      map.Ui.Mouse.enableDrag(false)
      map.Ui.Mouse.overlayDoubleClick(false)
      setTimeout(function() {
        // console.log("zoom timeout");
        map.Ui.Mouse.enableClick(true)
        map.Ui.Mouse.enableDrag(true)
        map.Ui.Mouse.overlayDoubleClick(true)
      }, 1200)
    })
    let allMarker = []

    this.setState({ map: map })

    map.Event.bind('ready', function() {
      // When map is ready to use

      let { maxLat, maxLon, minLat, minLon } = map.bound()

      let resObj = {
        nw: { lat: maxLat, lng: minLon },
        se: { lat: minLat, lng: maxLon },
      }

      let zoom = map.zoom()
      let center = map.location()

      self.props.setMapBound({
        bounds: resObj,
        center: { lat: center.lat, lng: center.lon },
        zoom: zoom,
      })

      map.Event.bind(
        'location',
        debounce(function() {
          allMarker = self.calculatedCluster(
            self,
            maps,
            map,
            self.state.allMarker,
          )
          self.setState({ allMarker })
        }, 700),
      )
      map.Event.bind(
        'zoom',
        debounce(function() {
          allMarker = self.calculatedCluster(
            self,
            maps,
            map,
            self.state.allMarker,
          )
          self.setState({ allMarker })
        }, 400),
      )

      self.setState({ map: map, maps: maps, mapMount: true }, () => {
        allMarker = self.calculatedCluster(
          self,
          maps,
          map,
          self.state.allMarker,
        )
        self.setState({ allMarker })
      })

      self.props.handleApiLoaded(map, maps)
    })
  }
  handleMapTypeSatellite = () => {
    // this.state.map.Layers.remove(this.state.maps.Layers.GRAY);
    // this.state.map.Layers.add(this.state.maps.Layers.THAICHOTE);
    this.state.map.Layers.setBase(window.longdo.Layers.GOOGLE_HYBRID)
  }

  handleMapTypeTerrain = () => {
    // this.state.map.Layers.remove(this.state.maps.Layers.THAICHOTE);
    // this.state.map.Layers.add(this.state.maps.Layers.GRAY);
    this.state.map.Layers.setBase(window.longdo.Layers.GOOGLE_ROADMAP)
  }

  render() {
    if (this.props.trickerclearMap === true) {
      this.state.map.Overlays.clear()
    }
    const mapType = (
      <Menu>
        <Menu.Item key="1" onClick={this.handleMapTypeSatellite}>
          <LangContext.Consumer>
            {i18n => i18n.s.satellite}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item key="2" onClick={this.handleMapTypeTerrain}>
          <LangContext.Consumer>{i18n => i18n.m.map}</LangContext.Consumer>
        </Menu.Item>
      </Menu>
    )
    return (
      <Fragment>
        <div
          style={{
            position: 'absolute',
            marginTop: '13px',
            top: '0px',
            right: '38px',
            zIndex: '12',
          }}
        >
          <ButtonGroup
            style={{
              marginRight: '4px',
            }}
          >
            <Dropdown className="edge-element" overlay={mapType}>
              <Button>
                <LangContext.Consumer>
                  {i18n => i18n.m.mapView}
                </LangContext.Consumer>{' '}
                <Icon type="down" />
              </Button>
            </Dropdown>
          </ButtonGroup>
        </div>

        {this.props.children}
      </Fragment>
    )
  }
}

export default Index
